<template>
    <div class="answer-reply">
        <el-dialog :title="`${this.questionTitle} -- ${question.userName}`" width="50%" :visible="visible"
            :before-close="handleClose">
            <!-- v-if="pages.currentPage <= 1" -->
            <div>
                <p class="question-title">{{this.questionTitle || '暂无标题'}}</p>
                <p class="question-instruction" v-html="question.content"></p>
                <div class="textarea-container" v-if="JSON.stringify(replyList) == '[]' && isMine">
                    <el-input type="textarea" :rows="2" placeholder="回复：" resize="none" v-model="textarea"
                        class="textarea" :maxlength="255" show-word-limit>
                    </el-input>
                    <div class="control-textarea">
                        <el-button type="primary" :plain="true" @click="commitReply(question)">回复</el-button>
                    </div>
                </div>
            </div>
            <div v-if="JSON.stringify(replyList) !== '[]'">
                <div class="child-comment" v-for="(item,index) in replyList" :key="index">
                    <div class="child-item flex-between">
                        <!-- 个人头像、回复信息 -->
                        <div class="person-info flex-start">
                            <img :src="downloadURL + item.userAvatar" alt="" class="avatar"
                                :onerror="$store.state.user.defaultAvatar">
                            <p class="f16 responder">
                                <el-tooltip class="item" effect="dark" :content="item.userName" placement="top"
                                    :disabled="item.userName.length <= 10">
                                    <span class="reply-name wes">{{item.userName}}</span>
                                </el-tooltip>
                                <span class="replay-text" style="color:#2373EC">回复</span>
                                <el-tooltip class="item" effect="dark" :content="item.parentUserName" placement="top"
                                    :disabled="item.parentUserName.length <= 10">
                                    <span class="reply-name wes">{{item.parentUserName}}</span>
                                </el-tooltip>
                            </p>
                            <p class="child-content">：{{item.content}}</p>
                        </div>
                        <!-- 右侧工具栏 -->
                        <div class="toolbar">
                            <span class="time">{{dayjs(item.createTime).format('YYYY-MM-DD HH:mm')}}</span>
                            <button class="btn csp" @click="reply(item)" v-if="isMine">回复</button>
                            <button class="btn csp" v-if="(item.userName === userInfo.name)"
                                @click="deleteReply(item)">删除</button>
                        </div>
                    </div>
                    <!-- 子级留言回复框 -->
                    <div class="textarea-container" v-if="$store.state.discuss.replyArea === item.id">
                        <el-input type="textarea" :rows="2" placeholder="回复：" resize="none" v-model="textarea"
                            class="textarea" :maxlength="255" show-word-limit>
                        </el-input>
                        <div class="control-textarea">
                            <el-button @click="$store.commit('getReplyArea',-1)">取 消</el-button>
                            <el-button type="primary" :plain="true" @click="commitReply(item)">回复</el-button>
                        </div>
                    </div>
                </div>
                <el-pagination :current-page.sync="pages.currentPage" :page-size.sync="pages.pageSize"
                    :total="pages.total" layout="prev, pager, next, total" @current-change="handlePageChange">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        deepClone,
    } from "@/utils/index";
    import {
        mapState
    } from "vuex";
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            questionId: {
                type: Number,
            },
            questionTitle: {
                type: String,
            },
            isMine: {
                type: Boolean,
            },
        },
        data() {
            return {
                title: '',
                textarea: '', // 回复内容
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                question: {}, // 问题
                replyList: [],
            }
        },
        computed: {
            ...mapState({
                replyArea: state => state.discuss.replyArea,
                userInfo: state => state.user.userInfo
            })
        },
        watch: {
            'questionId': {
                handler(newVal) {
                    if (!newVal) return;
                    this.getQuestion();
                },
                immediate: true,
            }
        },
        methods: {
            // 翻页
            handlePageChange(index) {
                this.pages.currentPage = index;
                this.getQuestion();
            },
            // 关闭回复框
            handleClose() {
                this.$emit('close', {
                    visible: false,
                })
                this.$store.commit('getReplyArea', -1);
                this.textarea = '';
                this.pages.currentPage = 1;
            },
            // TODO:通过ID请求具体问题
            async getQuestion() {
                let pramas = {
                    id: this.questionId,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                }
                let resData = await this.$Api.Course.getQuestionOne(pramas);
                // console.log(resData);
                if (resData.code != 200) {
                    console.log(resData.msg);
                    this.question = {};
                    this.replyList = [];
                    return;
                }
                this.pages.total = resData.data.total;
                this.replyList = deepClone(resData.data.records);
                if (this.pages.currentPage == 1) {
                    this.question = resData.data.records[0];
                    this.replyList.shift();
                }
            },
            // 回复
            reply(item) {
                this.$store.commit('getReplyArea', item.id)
            },
            // 提交子集回复
            async commitReply(item) {
                let pramas = {
                    content: this.textarea,
                    projectId: this.$store.state.user.currentProject.id,
                    // parentId: this.replyArea
                    parentId: item.id
                }
                let resData = await this.$Api.Course.putQuestion(pramas);
                // console.log(resData);
                if (resData.code !== 200) {
                    console.log(resData.msg);
                    this.$message.error(resData.msg);
                    return;
                }
                this.textarea = '';
                this.getQuestion();
                this.$message.success('回复成功');
                this.$store.commit('getReplyArea', -1)
            },
            // 删除回复
            deleteReply(item) {
                console.log(item);
                this.$confirm('是否删除该留言?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$Api.Course.delQuestionOne({
                        id: item.id
                    }).then((resData) => {
                        console.log(resData)
                        if (resData.code === 200 && resData.msg === "请求成功") {
                            this.$message.success('删除成功');
                            setTimeout(() => {
                                this.pages.currentPage = 1;
                                this.getQuestion();
                            }, 100)
                        } else {
                            this.$message.error(resData.msg);
                        }
                    })
                }).catch(() => {
                    this.$message('已取消删除');
                });
            }
        }
    }
</script>

<style lang="less" scoped>
    .answer-reply {
        /deep/ .el-dialog {
            border-radius: 10px;

            .el-dialog__header {
                border-bottom: 1px solid #E4E4E4;
                padding: 15px 31px 10px;
            }

            .el-dialog__title {
                font-size: 15px;
                color: #333;

                &::before {
                    content: '';
                    width: 5px;
                    height: 17px;
                    background-color: #01A2FD;
                    display: inline-block;
                    margin-right: 14px;
                    vertical-align: -3px;
                    border-radius: 3px;
                }
            }

            .el-dialog__headerbtn {
                top: 15px;
            }

            .question-title {
                font-size: 15px;
                margin-bottom: 15px;
            }

            .question-instruction {
                margin: 15px 0 35px;
            }



            .child-comment {
                margin-bottom: 15px;

                .avatar {
                    flex-shrink: 0;
                    width: 33px;
                    height: 33px;
                    margin-right: 10px;
                    border-radius: 50%;
                }

                .child-content {
                    word-break: break-all;
                }

                .responder {
                    flex-shrink: 0;
                    line-height: 33px;
                    font-size: 12px;


                    .reply-name {
                        max-width: 65px;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    .replay-text {
                        margin: 0 7px;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }


                .child-content {
                    display: inline-block;
                    padding-top: 6px;
                    padding-right: 10px;
                }

                .toolbar {
                    flex-shrink: 0;
                    padding-top: 8px;
                    font-size: 12px;

                    .btn {
                        margin-left: 20px;
                        font-size: 12px;
                    }
                }

                .textarea-container {
                    .textarea {
                        margin-left: 50px;
                    }
                }
            }

            .textarea-container {

                &.parent-textarea {
                    margin: 15px auto;
                }

                .control-textarea {
                    margin-top: 10px;
                    text-align: right;

                    /deep/ .el-button {
                        font-size: 12px;
                        padding: 8px 20px;
                    }
                }

                .textarea {
                    display: block;
                    font-size: 16px;
                    // margin-left: 50px;
                    margin-top: 10px;
                    width: auto;
                }
            }
        }
    }
</style>