<template>
    <!-- 考勤登记 -->
    <div class="ask">
        <div class="manage-wrapper table-wrapper">
            <h2 class="table-title">
                <img src="../../assets/images/home/ask.png" alt="" class="title-icon">我要提问
            </h2>
            <div class="ask-main flex-between">
                <!-- 左侧提问表单 -->
                <div class="left-form">
                    <el-form ref="askForm" :model="askForm" label-width="85px" :rules="rules" hide-required-asterisk
                        @submit.prevent.stop>
                        <el-form-item label="问题标题：" :required="true" prop="title">
                            <el-input v-model.trim="askForm.title" maxlength="30" show-word-limit class="form-item">
                            </el-input>
                        </el-form-item>

                        <!-- <el-form-item label="问题分类：">
                            <el-select v-model="askForm.questionType" placeholder="请选择问题分类" class="form-item">
                                <el-option v-for="item in questionTypeList" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item> -->

                        <el-form-item label="详细内容：" prop="content">
                            <!-- :height="500" :resize="true" -->
                            <tinymce v-model="askForm.content" placeholder="在这里输入问题描述" class="form-item"
                                :init="tinymceConfig">
                            </tinymce>

                        </el-form-item>

                        <el-form-item class="btns">
                            <button type="button" class="btn submit-btn" @click="onSubmit">提交</button>
                            <button type="button" class="btn cancel-btn" @click="emptyContent">清空</button>
                        </el-form-item>
                        <!-- {{askForm.title}}
                        {{askForm.questionDiscribe}} -->
                    </el-form>
                </div>
                <!-- 右侧问题列表 -->
                <div class="right-question">
                    <el-tabs v-model="activeTab" @tab-click="switchTab">
                        <el-tab-pane :label="item" :name="item" v-for="item in askTabs" :key="item">
                            <div v-if="pages.total > 0">
                                <ul class="question-list">
                                    <li class="question-item" v-for="(item, index) in questionList" :key="index">
                                        <div class="flex-align-between">
                                            <p class="quesiton-discribe 
                                    wes-2">{{ common.tableIndexMethod(index, pages.currentPage, pages.pageSize) }}.
                                                {{ item.title }}</p>
                                            <span style="color: #333;">{{mark[item.mark]}}</span>
                                        </div>

                                        <div class="tools-bar flex-align-between">
                                            <div class="tools">
                                                <button @click="checkReply(item)">查看回复</button>
                                                <button v-if="activeTab == '我的问题'" @click="editQuestion(item)">编辑</button>
                                                <button v-if="activeTab == '我的问题'" @click="delQuestion(item)">删除</button>
                                            </div>
                                            <span class="time">{{ item.createTime }}</span>
                                        </div>
                                    </li>
                                </ul>
                                <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                                    :limit.sync="pages.pageSize" @pagination="handlePageChange"
                                    :layout="'prev, pager, next, total, jumper'">
                                </pagination>
                            </div>
                            <Empty :show="pages.total <= 0" :text="'暂无问题'"></Empty>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <askReply :visible="visibleReplyDialog" @close="closeReply" :questionId="selectedQuestion.id"
            :questionTitle="selectedQuestion.title" :isMine="activeTab == '我的问题'">
        </askReply>
    </div>
</template>

<script>
import {
    mapState
} from 'vuex';
import askReply from './component/ask-reply.vue'
export default {
    data() {
        return {
            tinymceConfig: {
                height: 500,
                resize: false,
                removed_menuitems: 'print,template ',
                toolbar: "bold italic underline forecolor backcolor removeformat hr alignleft aligncenter alignright  bullist numlist charmap fullscreen", //  image
                // toolbar_drawer:'sliding',
                images_upload_url: `https://wstestv2.lezhireading.com:30443/api/fu/front/upload/file`,
                automatic_uploads: false,
                entity_encoding: "raw",
                wordlimit: {
                    max: 1000, // 最多可以输入多少字
                    spaces: !1, // 是否含空格
                    isInput: !1, // 是否在超出后还可以输入
                    // 自定义的提示方法, 默认用编辑器自带
                    toast: (message) => {
                        alert(message)
                    }
                },
            },
            common: Common,
            pages: { //分页
                currentPage: 1,
                pageSize: 5,
                total: 100,
            },
            checkInDialog: false,
            askTabs: ['我的问题', '其他学员问题'],
            activeTab: '我的问题',
            rules: {
                title: [{
                    required: true,
                    message: "请填写提问标题",
                    trigger: ["change", 'blur'],
                }],
                content: [{
                    required: true,
                    message: "请填写详细内容",
                    trigger: ["change", 'blur']
                }],
            },
            askForm: {
                id: undefined,
                title: '',
                // questionType: undefined,
                content: '',
            },
            questionTypeList: [{
                id: 1,
                name: '种类1'
            },
            {
                id: 2,
                name: '种类2'
            }
            ],
            questionList: [],
            isEdit: false,
            visibleReplyDialog: false,
            selectedQuestion: {},
            mark: {
                0: '暂无',
                1: '集中培训',
                2: '跟岗研修',
                3: '返岗实践'
            }
        }
    },
    components: {
        askReply
    },
    computed: {
        ...mapState({
            currentProject: state => state.user.currentProject,
        })
    },
    methods: {
        // fun(editor, txt, num) {
        //     // editor.execCommand('undo');
        //     console.log('当前字数：' + txt.length + '，限制字数：' + num);
        //     let wordCount = editor.plugins.wordcount.getCount();
        //     // console.log('wordCount',wordCount);
        //     // console.log(num);
        //     // this.$message.error(`当前字数：${wordCount}，限制字数：${num}`);
        // },
        // 切换问题组
        switchTab() {
            this.emptyContent();
            this.pages.currentPage = 1;
            this.getQuestionsList();
        },
        openDialog() {
            this.checkInDialog = true
        },
        checkReply(item) {
            this.selectedQuestion = item;
            this.visibleReplyDialog = true;
        },
        closeReply() {
            this.visibleReplyDialog = false;
            this.selectedQuestion = {};
        },
        // 翻页
        handlePageChange(data) {
            this.pages.currentPage = data.pageIndex;
            this.pages.pageSize = data.pageSize;
            this.getQuestionsList();
        },
        // 我的提问 、 其他学生提问
        async getQuestionsList() {
            let params = {
                projectId: this.currentProject.id,
                page: this.pages.currentPage,
                limit: this.pages.pageSize,
            }
            let resData = null;

            if (this.activeTab == '我的问题') {
                resData = await this.$Api.Course.getMyQuestions(params);
            }
            if (this.activeTab == '其他学员问题') {
                resData = await this.$Api.Course.getOtherQuestions(params);
            }

            if (JSON.stringify(resData.data.records) == '[]' || !resData) {
                this.questionList = [];
                this.pages.total = 0;
                return;
            }
            this.questionList = resData.data.records
            this.pages.total = resData.data.total
        },
        // 删除提问
        async delQuestion(item) {
            console.log(item);
            this.$confirm('是否删除该留言?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$Api.Course.delQuestionOne({
                    id: item.id
                }).then((resData) => {
                    console.log(resData)
                    if (resData.code === 200 && resData.msg === "请求成功") {
                        this.$message.success('删除成功');
                        setTimeout(() => {
                            this.pages.currentPage = 1;
                            this.getQuestionsList();
                        }, 100)
                    } else {
                        this.$message.error(resData.msg);
                    }
                })
            }).catch(() => {
                this.$message('取消删除');
            });
        },
        // 清空问题
        emptyContent() {
            this.$refs['askForm'].resetFields();
        },
        // 提交前的验证
        onSubmit() {
            this.$refs['askForm'].validate((valid) => {
                if (valid) {
                    this.commitQuestion();
                } else {
                    this.$message.error({
                        title: "错误",
                        message: "请正确填写数据"
                    });
                }
            })
        },
        // 提问
        async commitQuestion() {
            let pramas = {
                title: this.askForm.title,
                content: this.askForm.content,
                projectId: this.currentProject.id,
                parentId: 0
            }
            let resData = null;
            // 编辑
            if (this.isEdit) {
                resData = await this.$Api.Course.editQuestion(this.askForm);
            }
            // 创建
            if (!this.isEdit) {
                resData = await this.$Api.Course.putQuestion(pramas);
            }
            console.log(resData);
            if (resData.code != 200) {
                this.$message.error(resData.msg);
                return;
            }
            this.$message.success(this.isEdit ? '编辑成功' : '提问成功');
            setTimeout(() => {
                this.pages.currentPage = 1;
                this.getQuestionsList();
                this.$refs['askForm'].resetFields();
            }, 100)
        },
        // 获取当前问题的信息
        async editQuestion(item) {
            console.log(item);
            Vue.set(this.askForm, 'content', item.content);
            Vue.set(this.askForm, 'title', item.title);
            Vue.set(this.askForm, 'id', item.id);
            this.isEdit = true;
        }
    },
    mounted() {
        this.getQuestionsList();
    }
}
</script>

<style lang="less" scoped>
// @import "../manage/less/table.less";

.ask {
    .table-wrapper {
        border-radius: 8px;
        padding-top: 10px;
        padding: 0 28px 24px;
        box-shadow: 0px 2px 10px 0px #f5f8fa;
    }

    .table-title {
        line-height: 51px;
        font-size: 13px;
        border-bottom: 1px solid #f0f0f0;
        margin-bottom: 25px;
    }

    .title-icon {
        width: 13px;
        height: 13px;
        margin-right: 9px;
        vertical-align: -2px;
    }

    .left-form {
        margin-right: 30px;

        /deep/.el-form-item {
            margin-bottom: 23px;
        }

        .form-item {
            width: 312px;
        }

        /deep/ .tox-tinymce {
            width: 312px;
        }

        .btns {
            text-align: center;
            padding-top: 10px;

            .btn {
                display: inline-block;
                font-size: 12px;
                padding: 0 40px;
                line-height: 30px;
                color: #fff;
                background-color: rgba(36, 155, 253, 1);
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: rgba(36, 155, 253, .8);
                }
            }

            .cancel-btn {
                color: #999999;
                background-color: rgba(240, 240, 240, 1);
                margin-left: 30px;

                &:hover {
                    background-color: rgba(240, 240, 240, .6);
                }
            }
        }
    }

    .right-question {
        flex: 1;
    }

    .question-list {
        min-height: 571px;


        .question-item {
            border-bottom: 1px solid #f0f0f0;
            margin-bottom: 19px;

            &:last-child {
                border-bottom: 0;
            }
        }

        .quesiton-discribe {
            line-height: 24px;
            font-size: 13px;
        }

        .tools-bar {
            line-height: 50px;

            .tools {
                &>button {
                    cursor: pointer;
                    margin-right: 29px;
                    color: #518EF8;

                    &:hover {
                        color: rgba(81, 142, 248, .8);
                    }
                }
            }

            .time {
                font-size: 12px;
                color: #CCCCCC;
            }
        }
    }


    @media screen and (min-width:1250px) {
        .table-wrapper {
            padding: 0 43px 24px;
        }

        .table-title {
            line-height: 62px;
            font-size: 16px;
            margin-bottom: 30px;
        }

        .title-icon {
            width: 16px;
            height: 16px;
            margin-right: 12px;
        }

        .left-form {
            margin-right: 35px;

            .form-item {
                width: 378px;
            }

            /deep/ .tox-tinymce {
                width: 378px;
            }

            .btns {
                text-align: center;
                padding-top: 10px;

                .btn {
                    font-size: 14px;
                    padding: 0 48px;
                    line-height: 37px;
                }

                .cancel-btn {
                    margin-left: 36px;
                }
            }
        }

        .question-list {
            min-height: 571px;

            .question-item {
                margin-bottom: 23px;
            }

            .quesiton-discribe {
                line-height: 28px;
                font-size: 14px;
            }

            .tools-bar {
                line-height: 60px;

                .tools {
                    &>button {
                        margin-right: 35px;

                        &:hover {
                            color: rgba(81, 142, 248, .8);
                        }
                    }
                }

                .time {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>